import React from 'react'
import Layout from 'layouts/en'
import styled, { css } from 'styled-components'
import design from 'design'
import {Title, Text, Row, Div, Box, Button, Input, Img, FullWidthBg, Calendar} from 'components'
import {TryOrSchedule} from "containers/en";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import Island from "images/ConversationsCoppice8bitcopy.png";
import avatar from '../images/avatar.png'
import BALogo from '../images/ba-logo.png'
import photos2 from '../images/photos2.jpg'
import meetDesktop from '../images/meet-desktop-en.png'
import BareAddress from '../images/address.svg'
import BareEmail from '../images/email.svg'
import BarePhone from '../images/phone.svg'
import BareWebsite from '../images/website.svg'
import service from '../images/service.jpg'
import BareArrow from '../images/arrow.inline.svg'
import { getRandomInt } from 'utils'
import Moment from "react-moment";
import BareDots from 'images/dots.svg'
import { format } from 'utils'

const formats = format('en')

const Dots = styled(BareDots)`
  width: 4px;
  height: 24px;
`


const Arrow = styled(BareArrow)`
  width: 8px;
  height: 14px;
  transform: rotate(-90deg);
`

const svgs = css`
  height: 18px;
  width: auto;
`
const Address = styled(BareAddress)`${svgs}`
const Email = styled(BareEmail)`${svgs}`
const Phone = styled(BarePhone)`${svgs}`
const Website = styled(BareWebsite)`${svgs}`

export default () => {
  return (
    <Layout>
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={Island} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Acquire, engage, and retain customers</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[3,6]}>A dedicated website, all your interactions in one place, and a better ranking on Google.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.com/go" method="get">
              <Div display={['block','flex']}>
                <Input name="email" fontSize={[4,6]} p={[3,4]} placeholder={`Your email address`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Get Started</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Easy setup • Free for starters • No credit card required • Cancel any time</Text>
          </Div>
        </Div>
      </Box>

      <Div tag={'section'}>
        <Div id={'People'} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
          <Title href={'/en/customers#People'} mr={7} mb={4} fontSize={[5,8]} fontSize={[5,8]} fontWeight={700}>People</Title>
          <Title href={'/en/customers#Website'} mr={7} mb={4} fontSize={[5,8]} fontSize={[5,8]} fontWeight={400}>Website</Title>
          <Title href={'/en/customers#Conversation'} mr={7} mb={4} fontSize={[5,8]} fontSize={[5,8]} fontWeight={400}>Virtual Visit</Title>
        </Div>

        <Div id={'Manage'} display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title href={'/en/customers#Manage'} mr={[`73px`,6]} mb={4} fontSize={[3,4]} fontWeight={700}>Manage</Title>
              <Title href={'/en/customers#View'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>View</Title>
              <Title href={'/en/customers#Remember'} mr={[0,6]} mb={4} fontSize={[3,4]} fontWeight={400}>Remember</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Spend time on lasting relationships, not data entry</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Stairlin automatically feeds and updates customers information so your contact list is always up to date.</Text>
            </Div>
          </Div>
          <Div mx={[-36,0]} flex={1}>
            <Box boxShadow={'black'} bg={'white'} m={0} p={[4,6]}>
              <Div display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Div mb={6} display={'flex'}>
                  <Div display={'flex'}>
                    <img width={64} height={64} src={avatar} alt=""/>
                    <Div ml={4}>
                      <Text fontSize={6} fontWeight={700}>Leah Dresner</Text>
                      <Text fontSize={3} color={'grayDark'}>Altishausen, Switzerland</Text>
                    </Div>
                  </Div>
                </Div>
                <Div display={['none','block']}>
                  <Button py={2} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={1}>New reservation</Button>
                </Div>
              </Div>

              <Text mb={5} fontSize={[3,4]} fontWeight={700}>Contact Details</Text>
              <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'}>
                <Div order={1} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Phone</Text>
                  <Text fontSize={[2,3]}>052 281 18 26</Text>
                </Div>
                <Div order={2} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Email</Text>
                  <Text fontSize={[2,3]}>leah@stairlin.ch</Text>
                </Div>
                <Div order={[3,4]} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Birth date</Text>
                  <Text fontSize={[2,3]}>August 7, 1996</Text>
                </Div>
                <Div order={[4,5]} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Language</Text>
                  <Text fontSize={[2,3]}>German</Text>
                </Div>
                <Div order={[5,3]} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Address</Text>
                  <Text fontSize={[2,3]}>Bahnhofplatz 95<br/>
                    8573 Altishausen</Text>
                </Div>
                <Div order={6} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Referral</Text>
                  <Text fontSize={[2,3]} fontWeight={700} color={'primary'}>Éric Hamilton</Text>
                </Div>
              </Div>

              <Button textAlign={'center'} display={['block','none']} py={2} width={'100%'} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={1}>New reservation</Button>
            </Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Minimise manual data entry</Title>
            <Text fontSize={[2,4]}>Leverage online reservation and payment to minimise data entry and manual errors.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Import contacts</Title>
            <Text fontSize={[2,4]}>Import your existing contacts on Stairlin within minutes. Support includes Outlook, Gmail, vCard, and more.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Find anything, fast</Title>
            <Text fontSize={[2,4]}>Find reservations, invoices, notes, and more by name, keywords, or dates with our powerful search.</Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[36,6]} pt={[6,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/en/customers#Manage'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Manage</Title>
            <Title id={'View'} href={'/en/customers#View'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>View</Title>
            <Title href={'/en/customers#Remember'} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Remember</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Finally, everything in one place.</Title>
          <Text mb={[]} fontSize={[3,4]}>All your contact details, reservations, and invoices stored magically in one place.</Text>
          <Div textAlign={'left'} mx={[-36,0]} display={['block','flex']} mt={[6,110]}>
            <Div flex={1}>
              <Box boxShadow={'primaryDark'} bg={'white'} m={0} p={[4,6]}>
                <Div display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                  <Div mb={6} display={'flex'}>
                    <Div display={'flex'}>
                      <img width={64} height={64} src={avatar} alt=""/>
                      <Div color={'text'} ml={4}>
                        <Text fontSize={6} fontWeight={700}>Leah Dresner</Text>
                        <Text fontSize={3} color={'grayDark'}>Altishausen, Switzerland</Text>
                      </Div>
                    </Div>
                  </Div>
                  <Div display={['none','block']}>
                    <Button mr={3} py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Call</Button>
                    <Button mr={3} py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>New invoice</Button>
                    <Button py={2} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={1}>New reservation</Button>
                  </Div>
                </Div>

                <Div display={'flex'}>
                  <Div mr={7} display={['none','block']} color={'text'} flex={1}>
                    <Text fontWeight={700} borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Overview</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Contact Details</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Reservations</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Billing</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Notes</Text>
                  </Div>

                  <Div>
                    <Div color={'text'} flex={5}>
                      <Div display={'flex'} justifyContent={'space-between'}>
                        <Text mb={5} fontSize={[3,4]} fontWeight={700}>Contact Details</Text>
                        <Div display={['none','block']}>
                          <Button py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Edit</Button>
                        </Div>
                      </Div>
                      <Div display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Phone</Text>
                          <Text fontSize={[2,3]}>052 281 18 26</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Email</Text>
                          <Text fontSize={[2,3]}>leah@stairlin.ch</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Birth date</Text>
                          <Text fontSize={[2,3]}>August 7, 1996</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Language</Text>
                          <Text fontSize={[2,3]}>German</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Address</Text>
                          <Text fontSize={[2,3]}>Bahnhofplatz 95<br/>
                            8573 Altishausen</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Referral</Text>
                          <Text fontSize={[2,3]} fontWeight={700} color={'primary'}>Éric Hamilton</Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={6} color={'text'} flex={5}>
                      <Div display={'flex'} justifyContent={'space-between'}>
                        <Text mb={5} fontSize={[3,4]} fontWeight={700}>Billing</Text>
                        <Div display={['none','block']}>
                          <Button py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Invoice now</Button>
                        </Div>
                      </Div>
                      <Div display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Customer balance</Text>
                          <Text fontSize={[5,6]}>CHF 120.00</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Pending Items</Text>
                          <Text fontSize={[5,6]}>CHF 120.00</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Outstanding amount</Text>
                          <Text fontSize={[5,6]}>CHF 0.00</Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={6} color={'text'} flex={5}>
                      <Div display={'flex'} justifyContent={'space-between'}>
                        <Text mb={5} fontSize={[3,4]} fontWeight={700}>Recent interactions</Text>
                        <Div display={['none','block']}>
                          <Button py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>View more interactions</Button>
                        </Div>
                      </Div>

                      <Div>
                        <Div>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.primary}`}>
                              <Div height={[42,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                              <Div width={20} height={20} borderRadius={'50%'} border={`2px solid ${design.color.white}`}>
                                <Div width={16} height={16} borderRadius={'50%'} bg={`primary`}></Div>
                              </Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Invoice settled</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>IE-18-366-381 for CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                              <Div height={[42,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>CHF 120.00 paid by Leah Dresner</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>Visa •••• 5791</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                              <Div height={[42,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Invoice released</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>IE-18-366-381 for CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:0,h:5,m:20 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                              <Div height={[59,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Pending item added</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>Acupuncture - first session - 1 hour - CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:2,h:8,m:30 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Reservation confirmed</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>Acupuncture - first session - 1 hour - CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:2,h:8,m:30 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                  </Div>

                </Div>
              </Box>
            </Div>
          </Div>

          <Div textAlign={'left'} display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Understand your customers</Title>
              <Text fontSize={[2,4]}>Sort and search customers and see their reservation and payment habits.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Access anywhere</Title>
              <Text fontSize={[2,4]}>Everything you put in Stairlin is automatically synced across your all your devices, and is securely accessible through a web browser.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Safe & Secure</Title>
              <Text fontSize={[2,4]}>Safe, secure cloud storage always keeps your information protected and private.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Remember'} mt={[6,8]}>
          <Div display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/customers#Manage'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Manage</Title>
                <Title href={'/en/customers#View'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>View</Title>
                <Title href={'/en/customers#Remember'} ml={[0,6]} mr={[0,0]} mb={4} fontSize={[3,4]} fontWeight={700}>Remember</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Remember everything important
                  about each customer</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Capture and organise notes in one place.</Text>
              </Div>
            </Div>
            <Div mx={[-48,0]} flex={1}>
              <Box bg={'white'} m={0} p={[4,6]}>
                <Div display={'flex'}>
                  <img width={64} height={64} src={avatar} alt=""/>
                  <Div color={'text'} ml={4}>
                    <Text fontSize={6} fontWeight={700}>Leah Dresner</Text>
                    <Text fontSize={3} color={'grayDark'}>Altishausen, Switzerland</Text>
                  </Div>
                </Div>
                <Div minHeight={200} borderRadius={5} p={3} mt={4} border={`1px solid ${design.color.grayLight}`}>
                  <Text fontSize={3}>Leah got regularly severe headaches, and basic headache medication is not effective at all.</Text>
                </Div>
                <Button mt={4} py={3} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={4}>Add Note</Button>
              </Box>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Website'} mt={[6,8]}>
          <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/customers#People'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>People</Title>
            <Title href={'/fr/customers#Website'} mr={7} mb={4} fontSize={[5,8]} fontWeight={700}>Website</Title>
            <Title href={'/fr/customers#Conversation'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Virtual Visit</Title>
          </Div>

          <Div id={'Boost'} display={['block','flex']}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/customers#Boost'} mr={[`90px`,6]} mb={4} fontSize={[3,4]} fontWeight={700}>Boost</Title>
                <Title href={'/en/customers#Grow'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Grow</Title>
              </Div>
              <Div mr={[0,6]} textAlign={['center','left']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Build a beautiful online home and gain an instant professional edge.</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>No code or technical mumbo jumbo.</Text>
              </Div>
            </Div>
            <Div mx={[-48,0]} flex={1}>
              <Box bg={'white'} m={0} p={[4,4]}>
                <Div mx={[-4,-4]} mt={[-4,-4]}>
                  <img width={['100%']} src={photos2} alt=""/>
                </Div>
                <Div mt={4} mb={[5,6]} px={`13px`} display={'flex'} justifyContent={'space-between'}>
                  <Div display={'flex'}>
                    <Img width={[32,48]} height={[32,48]} src={BALogo} alt=""/>
                    <Div ml={[`16px`,`12px`]}>
                      <Text fontSize={[0,4]} fontWeight={700}>Blossom Acupuncture</Text>
                      <Text fontSize={[0,3]}>Lausanne - Swtizerland</Text>
                    </Div>
                  </Div>
                  <Div>
                    <Button py={[2,`12px`]} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={[0,4]}>Reserve Now</Button>
                  </Div>
                </Div>

                <Div ml={[4,5]} display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={[4,`30px`]} pt={`2px`}>
                      <Address/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Address</Text>
                      <Text fontSize={[0,2]}>Infinite Loop 1<br/>
                        1003 Lausanne</Text>
                    </Div>
                  </Div>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={[4,`30px`]} pt={`2px`}>
                      <Email/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Email</Text>
                      <Text fontSize={[0,2]}>blossom@stairlin.app</Text>
                    </Div>
                  </Div>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={[4,`30px`]} pt={`2px`}>
                      <Phone/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Phone</Text>
                      <Text fontSize={[0,2]}>027 970 69 43</Text>
                    </Div>
                  </Div>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={[4,`30px`]} pt={`2px`}>
                      <Website/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Website</Text>
                      <Text fontSize={[0,2]}>blossom.ch</Text>
                    </Div>
                  </Div>
                </Div>

              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Your Business Online</Title>
              <Text fontSize={[2,4]}>Set up your Page and get a beautiful online presence in minutes. Your Page will look great on both desktop and mobile.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Mobile Friendly</Title>
              <Text fontSize={[2,4]}>Look amazing on every screen with a mobile-friendly version of your website.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Better Ranking - SEO</Title>
              <Text fontSize={[2,4]}>Our system is constantly optimised for all search engines to get your website found on Google.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[36,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/en/customers#Boost'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Boost</Title>
            <Title id={'Grow'} href={'/en/customers#Grow'} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Grow</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Manage and grow your business online</Title>
          <Text mb={[]} fontSize={[3,4]}>No matter who you are — therapist, photographer, hair stylist, driving instructor and more, you can grow your audience with online booking.</Text>
          <Div display={['block','flex']} mt={[140,110]}>

            <Div mt={[0,`78px`]} mx={[-36,0]} flex={1} mb={6}>
              {/*<Title mb={[4,6]} fontSize={[3,6]}></Title>*/}
              <Box position={'relative'} boxShadow={'primaryDark'} height={['auto',576]} color={'text'} textAlign={'left'} bg={'white'} m={0} mx={[0,4]} p={[4,5]}>
                <Div position={'absolute'} top={-78}>
                  <Div zIndex={1} position={'absolute'} top={0} width={['100%',381]} height={78} boxShadow={'primaryDark'} />
                  <Img zIndex={2} position={'absolute'} top={0} width={['100%',381]} borderRadius={12} src={service} />
                </Div>
                <Div mt={`102px`}>
                  <Text fontWeight={700} mb={[4,5]} mt={[4,4]} fontSize={[4,6]}>Acupuncture</Text>
                </Div>
                <Div display={'flex'}>
                  <Div flex={1}>
                    <Text fontSize={[2,4]} fontWeight={700}>Duration</Text>
                    <Text fontSize={[2,4]}>1 hour</Text>
                  </Div>
                  <Div flex={1}>
                    <Text fontSize={[2,4]} fontWeight={700}>Price</Text>
                    <Text fontSize={[2,4]}>CHF 120.00</Text>
                  </Div>
                </Div>
                <Text mt={[4,5]} mb={[6,7]} color={'grayDark'} fontSize={3}>
                  Acupuncture is a form of alternative medicine and a key component of traditional Chinese medicine (TCM) in which thin needles are inserted into the body.
                </Text>
                <Div display={'flex'} justifyContent={'space-between'} mt={[4,`98px`]} alignItems={'center'}>
                  <Div flex={1} mr={4}>
                    <Button width={'100%'} onClick={()=>{}} py={2} height={`48px`} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={4}>Book</Button>
                  </Div>
                  <Div>
                    <Div display={'flex'} justifyContent={'center'} alignItems={'center'} boxShadow={'black'} borderRadius={5} height={`48px`} width={`48px`}>
                      <Dots />
                    </Div>
                  </Div>
                </Div>
              </Box>
            </Div>

            <Div mx={[-48,0]} flex={1}>
              {/*<Title mb={[4,6]} fontSize={[3,6]}></Title>*/}
              <Div px={[0,110]}>
                <Box boxShadow={'primaryDark'} color={'text'} textAlign={'left'} bg={'white'} m={0} pb={1}>
                  <Div display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={`20px`} px={5} borderBottom={`1px solid #CCC`}>
                    <Text fontSize={3} fontWeight={700}>Pick a day and time</Text>
                    <Arrow/>
                  </Div>

                  <Calendar lang={'en'} />

                  <Div mx={4} mt={5}>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>09:00 - 10:00</Div>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>13:00 - 14:00</Div>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>18:00 - 19:00</Div>
                    <Div mb={4} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>Other time slots…</Div>
                  </Div>
                </Box>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Conversation'} mt={[6,8]}>
          <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/customers#People'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>People</Title>
            <Title href={'/fr/customers#Website'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Website</Title>
            <Title href={'/fr/customers#Conversation'} mr={7} mb={4} fontSize={[5,8]} fontWeight={700}>Virtual Visit</Title>
          </Div>

          <Div id={'Meet'} display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Rethinking the way you give care</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>With an easy-to-use video consultation system, 100% secure, and which respects your privacy.</Text>
              </Div>
            </Div>
            <Div mx={[-48,0]} flex={1}>
              <Box bg={'white'} m={0} p={[4,4]}>
                <img width={['100%']} src={meetDesktop} alt=""/>
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Break down walls</Title>
              <Text fontSize={[2,4]}>Reduce travels and time wasted in your waiting room by carrying out your services online.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Reduce contamination</Title>
              <Text fontSize={[2,4]}>The WHO is recommending remote services to improve improve health care access and outcomes.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Security & privacy</Title>
              <Text fontSize={[2,4]}>We value your privacy and ensures optimal security with our peer-to-peer communication system.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>
    </Layout>
  )
}
